import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-light/arrow-small-right-light.svg';
import { ContainerRow } from '@src/components/styled';
import IconText from '@shared/ui/icons/IconText';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import Button from '@shared/ui/buttons/Button';
const IconTextStyled = styled(IconText)((props) => ({
    '& .MuiTypography-root': {
        fontSize: 16,
        fontWeight: 500,
        color: props.theme.palette.text.primary,
    },
}));
const VerificationBannerContainer = styled(ContainerRow)(props => ({
    padding: props.theme.spacing_sizes.xm,
    boxSizing: 'border-box',
    backgroundColor: '#F8F9FA',
    justifyContent: 'space-between',
    borderRadius: 6,
}));
const GetVerificationBanner = () => {
    const { t } = useTranslation();
    return (_jsxs(VerificationBannerContainer, { children: [_jsx(IconTextStyled, { IconComponent: _jsx(VerificationIcon, { verified: true, size: 24 }), text: t('profile.get_verification.text', { ns: 'common' }) }), _jsx(Button, { variant: 'plain', size: 'small', endIcon: ArrowRightIcon, children: t('profile.get_verification.btn_text', { ns: 'common' }) })] }));
};
export default GetVerificationBanner;
