import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import { BodyMedium } from '@components/styled/Typography';
import { ReactComponent as CheckmarkIconComponent } from '@icons/wolfkit-solid/checkmark-without-check-circle-solid.svg';
import { ReactComponent as XmarkIconComponent } from '@icons/wolfkit-light/xmark-light.svg';
import ButtonCustom from '../../../../../shared/ui/buttons/ButtonCustom';
import CompletionIconText from './CompletionIconText';
const CompleteProfileContainer = styled.div(props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderLeft: `6px solid ${props.theme.palette.primary.main}`,
    borderRadius: 6,
    padding: `${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.xm}px`,
    backgroundColor: props.theme.customColors.input.backgroundDisabled,
}));
const CloseButton = styled(ButtonCustom)(props => ({
    '&.MuiButtonBase-root': {
        minWidth: 'unset',
        minHeight: 'unset',
        padding: 0,
        transition: 'none',
        backgroundColor: 'transparent',
        border: 'none',
    },
    path: {
        fill: props.theme.palette.text.secondary,
    },
    '&:hover': {
        border: 'none',
    },
}));
const BannerText = styled(BodyMedium)(props => ({
    display: 'inline-flex',
    lineHeight: '34px',
    fontWeight: 400,
    color: props.theme.palette.text.primary,
    gap: props.theme.spacing_sizes.s,
}));
const XmarkIcon = styled(XmarkIconComponent)(props => ({
    width: 18,
    height: 18,
    path: {
        fill: props.theme.customColors.input.border,
    },
}));
const CompleteProfileBanner = ({ isLocationFilled = false, isPublicNameFilled = false, onClose = undefined, }) => (_jsxs(CompleteProfileContainer, { children: [_jsx(BannerText, { children: Trans({
                ns: 'translation',
                i18nKey: 'profile.complete_profile',
                components: {
                    PublicName: (_jsx(CompletionIconText, { isComplete: isPublicNameFilled, IconComponent: CheckmarkIconComponent })),
                    Location: (_jsx(CompletionIconText, { isComplete: isLocationFilled, IconComponent: CheckmarkIconComponent })),
                },
            }) }), _jsx(CloseButton, { onClick: onClose, children: _jsx(XmarkIcon, {}) })] }));
export default CompleteProfileBanner;
